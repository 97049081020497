import type { User } from '../server/models/user.model'

interface UserPayloadInterface {
  username: string
  password: string
}

interface State {
  authUser?: User
  incorrectLogin: boolean
  authenticated: boolean
  loading: boolean
}

const state: Ref<State> = ref({
  authUser: undefined,
  incorrectLogin: false,
  authenticated: false,
  loading: false
})

const useUser = () => {
  const router = useRouter()
  const getters = {
    get incorrectLogin(): ComputedRef<boolean>{
      return computed(() => state.value.incorrectLogin)
    },
    get loading(): ComputedRef<boolean>{
      return computed(() => state.value.loading)
    }
  }

  const setters = {
    set authenticated(a: boolean) {
      state.value.authenticated = a
    }
  }

  const actions = {
    async authenticateUser({ username, password }: UserPayloadInterface) {
      // useFetch from nuxt 3
      const { data, pending }: any = await useFetch('/api/auth/login', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: {
          username,
          password,
        },
      });
      state.value.loading = pending;
      if (data.value.success) {
        const token = useCookie('token'); // useCookie new hook in nuxt 3
        const loginDate = useCookie('logindate')
        token.value = data.value.data.token; // set token to cookie
        loginDate.value = new Date().getTime().toString()
        state.value.authenticated = true; // set authenticated  state value to true
        if (data.value.data.user) state.value.authUser = data.user
        router.push('/admin')
      } else {
        console.log('app: Incorrect login')
        state.value.incorrectLogin = true
        setTimeout(() => {
          state.value.incorrectLogin = false
        }, 1000)
      }
    },
    logUserOut() {
      const token = useCookie('token'); // useCookie new hook in nuxt 3
      state.value.authenticated = false; // set authenticated  state value to false
      token.value = null; // clear the token cookie
    },
    /* async login({ username, password }: {username: string, password: string }) {
      try {
        const data = await $fetch('/api/auth/login', {
          method: 'get',
          params: {
            username,
            password
          }
        })
        if (data.user) {
          state.value.authUser = data.user
          router.push('/admin')
        } else {
          console.log('app: Incorrect login')
          state.value.incorrectLogin = true
          setTimeout(() => {
            state.value.incorrectLogin = false
          }, 1000)
        }
      } catch (err: unknown) {
        console.log('app: Login ERROR')
      }
    }, */
  }
  return {
    getters,
    setters,
    actions,
  }
}

export type UserStoreType = ReturnType<typeof useUser>
export default useUser
